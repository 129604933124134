import React from "react"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import {Link} from "gatsby"

import "../styles/about.css"

const About = () => {
    return (
        <Layout>
            <div className="aboutHero md:pb-16">
                <div className="container mx-auto pt-24 pb-8 text-center lg:w-2/3">
                    <h1 className="text-3xl md:text-4xl lg:text-5xl text-white font-normal text-shadow px-2">
                        <span className="font-thin">About</span> The Digital
                        Entrepreneurship Ecosystem
                    </h1>
                </div>
            </div>
            {/* Completed responsiveness */}

            <div className="container mx-auto md:px-16 py-6">
                <div className="flex justify-center mx-8">
                    <div className="w-full lg:w-2/3 mt-12">
                        <h1 className="text-blue-500 text-center text-2xl md:text-4xl font-semibold">
                            <span className="font-thin">What is </span> Digital
                            Entrepreneurship Ecosystem?
                        </h1>
                        <div className="flex justify-center mt-6">
                            <iframe
                                width="900"
                                height="650"
                                // src="https://vimeo.com/569049305"
                                src="https://www.youtube.com/embed/S6UNdGHWy9U"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                className="rounded-md"
                            ></iframe>
                        </div>
                        <div className="mt-10">
                            <p className="text-justify mt-4 leading-loose font-light">
                                <Link to="/" className="text-blue-500 font-bold">
                                    Entrepreneurs.gov.sl
                                </Link>{" "}
                                is a governmental initiative created in (2020), led by the
                                Directorate of Science Technology and Innovation aimed to
                                supercharge the business ecosystem by bringing Small and
                                Medium-sized Enterprises(SMEs), together to promote nation-wide
                                entrepreneurship strategy, financing and investments,
                                sustainability, while protecting and recognizing the unique
                                product and services of SMEs ranging from Fintech and eCommerce,
                                Agro and Food Technologies, Software Applications, Digital
                                Health and Medical, Education and Knowledge, Security and
                                Safety, Social Media and Advertising, Industrial Technologies,
                                Mobile and Telecom, Enterprise Solutions, Consumer Electronics
                                and many more emerging industries.
                            </p>
                            <div className="mt-12">
                                <StaticImage
                                    src="../images/about/Ecosystem Team.jpeg"
                                    alt="Ecosystem Team"
                                    className="w-full shadow-lg rounded-md"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Completed responsiveness */}

                {/* What the Entrepreneurship ecosystem does */}
                <div className="container mx-auto md:px-16 mt-20 px-10 md:w-2/3">
                    <div className=" flex justify-center">
                        <div>
                            <h3 className="font-normal text-center text-blue-500 text-2xl md:text-3xl">
                                <span className="font-thin">What exactly</span> does the digital
                                ecosystem do?
                            </h3>
                        </div>
                    </div>
                    <div className="mt-6">
                        <p className="text-lg font-bold">
                            Today, the SL Digital Ecosystem divides its activities into 4
                            categories:
                        </p>
                        <ul className="mt-2">
                            <li className="leading-6 mb-4 text-justify">
                <span className="text-blue-500 font-bold">
                  Strengthening the Sierra Leone entrepreneurship ecosystem
                </span>{" "}
                                – through this platform, we desire to unite and build a
                                community of problem-solvers through different events and
                                activities, creating and executing unified marketing and
                                branding strategies, assisting scale up development and
                                data-based decision making.
                            </li>
                            <li className="leading-6 mb-4 text-justify">
                <span className="text-blue-500 font-bold">
                  Be part of organizing entrepreneurship events
                </span>{" "}
                                – The aim here is through community Mobilisation, advocating
                                diversity in age, gender, sector and locations.
                            </li>
                            <li className="leading-6 mb-4 text-justify">
                <span className="text-blue-500 font-bold">
                  Partnership opportunities
                </span>{" "}
                                – working with both local and international partners to up skill
                                the entrepreneurship community in Sierra Leone. Also, through
                                partnerships, connecting entrepreneurs to available resources
                                and vital information and know-how.
                            </li>
                            <li className="leading-6 mb-4 text-justify">
                <span className="text-blue-500 font-bold">
                  Working on policy and regulatory issues and barriers
                </span>{" "}
                                – advocating for the change of bad policies and regulations that
                                are complicating the process of operating business, investment
                                or access to funding in Sierra Leone, and implementing
                                entrepreneurship friendly regulations.
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Completed responsiveness */}

                {/* APPROACH SECTION */}
                <div className="mt-16 flex justify-center">
                    <div className="px-10 md:w-1/2 text-center">
                        <h2 className="text-4xl text-blue-500 font-semibold">
                            Our approach
                        </h2>
                        <p className="mt-5 font-normal leading-loose">
                            We embrace a radical inclusion-model of entrepreneurship that
                            defies age, gender, culture and background to attract national,
                            and international commercial activities by creating an ideal
                            system that will enable the swift flow of tracking businesses
                            including, startups or fully established all aroun1 the nation.
                        </p>
                    </div>
                </div>

                <div className="flex justify-center mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 mx-10 lg:w-2/3">
                        <div className="flex mr-4">
                            <div className="mr-3">
                                <svg
                                    className="w-12 h-12 text-blue-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                        d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                                    ></path>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                        d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-blue-500 text-2xl font-semibold">
                                    Education
                                </h3>
                                <p className="font-light mt-1">
                                    We offer content, events, and initiatives that are geared
                                    towards entrepreneurial learning, growth and strengthening
                                </p>
                            </div>
                        </div>

                        <div className="flex mt-6 md:mt-0">
                            <div className="mr-3">
                                <svg
                                    className="w-12 h-12 text-blue-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                        d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                                    ></path>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                        d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-blue-500 text-2xl font-semibold">
                                    Collaboration
                                </h3>
                                <p className="font-light mt-1">
                                    A valuable network of cross-sectoral champions and experts to
                                    establish a multi-sector dynamic that will create a conducive
                                    business environment in Sierra Leone
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-6 mx-10">
                    <div className="flex md:w-2/4">
                        <div className="flex">
                            <div className="mr-3">
                                <svg
                                    className="w-12 h-12 text-blue-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                        d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                                    ></path>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                        d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <h3 className="text-blue-500 text-2xl font-semibold">
                                    Support and Sustainability
                                </h3>
                                <p className="font-light mt-1">
                                    Provide real-time data for decision making and forecasting
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Completed responsiveness */}

                <div className="flex justify-center mt-16 mx-10">
                    <div className="md:w-1/2 text-center">
                        <Link
                            to="/ecosystem-benefits"
                            className="text-blue-500 text-lg underline"
                        >
                            See reasons why you should join Entrepreneurship Ecosystem Sierra
                            Leone
                        </Link>
                    </div>
                </div>
                {/* Completed responsiveness */}

                {/* MISSION & VISION */}
                <div className="flex justify-center mx-10 md:mx-0">
                    <div className="md:w-2/3 mt-20">
                        <h1 className="text-blue-500 text-center text-3xl">
                            Our Mission and Vision
                        </h1>
                        <p className="text-justify mt-4 text-lg font-light">
                            Using technology and innovation to develop an accurate and
                            reliable database system of all businesses and entrepreneurs in
                            Sierra Leone.
                        </p>
                        <div className="mt-12">
                            <StaticImage
                                src="../images/about/About Mission.jpeg"
                                alt="About Mission"
                                className="w-full shadow-lg rounded-md"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default About
